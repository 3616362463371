import * as React from "react"
import { Box } from "react-polymer-layout"

import * as util from "../util"
import Toast, { toast } from "./Toast"

interface Props {
    title?: string
    description?: string
    pic?: string
    iconText?: boolean
}

interface State {
    open: boolean
}

export default class ShareIcons extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            open: true,
        }
    }

    componentDidMount() {
        document.title = this.props.title || document.title;
        const wx = (window as any).wx
        if (wx) {
            wx.ready(() => {
                wx.onMenuShareTimeline({
                    title: (this.props.title || document.title) + " - 分享自领研",
                    link: location.origin + location.pathname + location.search,
                    imgUrl: this.props.pic,
                })
                wx.onMenuShareAppMessage({
                    title: this.props.title || document.title,
                    desc: this.props.description || "分享自领研",
                    link: location.origin + location.pathname + location.search,
                    imgUrl: this.props.pic,
                })
            })
        }
    }

    shareIcon(name: string, text: string, href, onClick?) {
        let icon = (
            <Box center>
                <Box center centerJustified className="bg-color-primary clickable"
                    onClick={onClick} style={{
                        height: "1.6em", width: "1.6em", borderRadius: 999,
                        color: "white",
                    }}>
                    <i className={`fa fa-${name}`} />
                </Box>
                {this.props.iconText ? <span className="text-primary" style={{ marginLeft: ".5em" }}>{text}</span> : null}
            </Box>
        )
        return href ? <a target="_blank" href={href}>{icon}</a> : icon
    }

    render() {
        return (
            <Box>
                <Toast ref="toast" />

                {this.shareIcon("weixin", "微信", "", () => {
                    toast(this, {
                        title: "分享到微信",
                        content: util.isWechatBrowser() ? "点击右上角发送给朋友或分享到朋友圈" : "请使用浏览器的「分享到微信」功能",
                        confirmText: "我知道了",
                    })
                })}
                {util.minWidth(".8em")}
                {this.shareIcon(
                    "weibo", "微博",
                    `http://service.weibo.com/share/share.php` +
                    `?url=${encodeURIComponent(location.href)}` +
                    `&title=${encodeURIComponent((this.props.title || document.title) + " - 分享自领研")}` +
                    (this.props.pic ? `&pic=${this.props.pic}` : "")
                )}
                {util.minWidth(".8em")}
                {this.shareIcon(
                    "qq", "QQ",
                    `http://connect.qq.com/widget/shareqq/index.html` +
                    `?url=${encodeURIComponent(location.href)}` +
                    `&title=${encodeURIComponent(this.props.title || document.title)}` +
                    `&summary=${encodeURIComponent("分享自领研")}` +
                    (this.props.pic ? `&pics=${this.props.pic}` : "")
                )}
            </Box>
        )
    }
}

export class ShareIcons2 extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            open: true,
        }
    }

    componentDidMount() {
        document.title = this.props.title || document.title;
        const wx = (window as any).wx
        if (wx) {
            wx.ready(() => {
                wx.onMenuShareTimeline({
                    title: (this.props.title || document.title) + " - 分享自领研",
                    link: location.origin + location.pathname + location.search,
                    imgUrl: this.props.pic,
                })
                wx.onMenuShareAppMessage({
                    title: this.props.title || document.title,
                    desc: this.props.description || "分享自领研",
                    link: location.origin + location.pathname + location.search,
                    imgUrl: this.props.pic,
                })
            })
        }
    }

    shareIcon(name: string, href, onClick?) {
        let icon = <img src={util.iconURL(name + ".svg")} style={{ height: 23, verticalAlign: "bottom" }} onClick={onClick} />
        return href ? <a target="_blank" href={href}>{icon}</a> : icon
    }

    render() {
        return (
            <Box center>
                <Toast ref="toast" />

                <div style={{ fontSize: 13, color: "#666", whiteSpace: "nowrap" }}>
                    分享至
                </div>
                {util.minWidth(20)}
                {this.shareIcon("wechat", "", () => {
                    toast(this, {
                        title: "分享到微信",
                        content: util.isWechatBrowser() ? "请使用微信的分享功能" : "请使用浏览器的“分享到微信”功能",
                        confirmText: "我知道了",
                    })
                })}
                {util.minWidth(20)}
                {this.shareIcon(
                    "weibo",
                    `http://service.weibo.com/share/share.php` +
                    `?url=${encodeURIComponent(location.href)}` +
                    `&title=${encodeURIComponent((this.props.title || document.title) + " - 分享自领研")}` +
                    (this.props.pic ? `&pic=${this.props.pic}` : "")
                )}
                {util.minWidth(20)}
                {this.shareIcon(
                    "qq",
                    `http://connect.qq.com/widget/shareqq/index.html` +
                    `?url=${encodeURIComponent(location.href)}` +
                    `&title=${encodeURIComponent(this.props.title || document.title)}` +
                    `&summary=${encodeURIComponent("分享自领研")}` +
                    (this.props.pic ? `&pics=${this.props.pic}` : "")
                )}
            </Box>
        )
    }
}
