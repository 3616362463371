import * as React from "react"
import { Box, Item } from "react-polymer-layout"
import { ChevronUpIcon } from "../../components/FontawesomeIcon"

import * as util from "../util"

export function displayScrollTop(display: string) {
    let icon = document.getElementById("scroll-to-top") as HTMLElement
    if (icon) icon.style.display = display
    return null
}

export function scrollToTop(selector = "#root>div", background = "#290382") {
    return <ChevronUpIcon 
            id="scroll-to-top" 
            iStyle={{fontSize: 24, padding: 8, width: 40, textAlign: "center",
                position: "fixed", bottom: 30, right: 15,
                color: "white", opacity: 0.8, borderRadius: 5,
                background, zIndex: 20,}} 
            color="#fff"
            onClick={() => {
                let body = document.querySelector(selector) as HTMLElement
                if (body) body.scrollTop = 0
            }}/>
}

export function detailHeader(children?, logoType = "-font", logoHeight = 24, logoWidth = 79, boxShadow = '0 2px 4px rgba(0,0,0,0.1)') {
    let height = 50
    return [
        <div key="blank" style={{ height }} />
        ,
        <Box key="main" center
            style={{
                height, padding: "0 15px", boxShadow: boxShadow, zIndex: 3,
                position: "fixed", background: "white", width: "100%",
            }}>
            {util.logo({ height: logoHeight, width: logoWidth }, logoType)}
            <Item flex style={{ minWidth: 38 }} />
            {children}
        </Box>
    ]
}
