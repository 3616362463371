import * as React from "react"
import { Box } from "react-polymer-layout"
import { get, set } from "object-path"

import countryCodes from "../../misc/countryCodes"
import * as util from "../util"

export const elements = {
    formInput(that, key, props) {
        switch (props && props.type) {
            case "radio":
                let onClick = i => {
                    set(that.state, key, props.options[i].value)
                    that.setState({})
                }
                let selected = i => (
                    <div onClick={() => onClick(i)}
                        style={{ height: ".8em", borderRadius: 999, width: ".8em", border: ".3em solid #290382" }}>
                    </div>
                )
                let unselected = i => (
                    <div onClick={() => onClick(i)}
                        style={{ height: ".8em", borderRadius: 999, width: ".8em", border: "1px solid #e5e5e5" }}>
                    </div>
                )
                return (
                    <Box center style={{ height: "1em" }}>
                        {get(that.state, key) === props.options[0].value ? selected(0) : unselected(0)}
                        {util.minWidth(".5em")}
                        {props.options[0].text}
                        {util.minWidth("1em")}
                        {get(that.state, key) === props.options[1].value ? selected(1) : unselected(1)}
                        {util.minWidth(".5em")}
                        {props.options[1].text}
                    </Box>
                )
            case "mobile":
                if (get(that.state, key + ".countryCode") === undefined) {
                    set(that.state, key + ".countryCode", "86")
                }
                return (
                    <Box>
                        {elements.formInput(that, key + ".countryCode", {
                            type: "select",
                            options: countryCodes.map(x => {
                                return {
                                    value: x.value,
                                    text: x.text + " " + x.value,
                                    input: x.value,
                                }
                            }),
                            style: {
                                borderBottomRightRadius: 0,
                                borderTopRightRadius: 0,
                                borderRight: "none",
                                width: "6em",
                            }
                        })}
                        {elements.formInput(that, key + ".phoneNumber", {
                            placeholder: "您的手机",
                            type: "tel",
                            style: {
                                paddingLeft: 0,
                                borderBottomLeftRadius: 0,
                                borderTopLeftRadius: 0,
                                borderLeft: "none",
                            }
                        })}
                    </Box>
                )
            case "select":
                if (get(that.state, key) === undefined) {
                    set(that.state, key, props.options[0].value)
                }
                let text
                for (let i = 0; i < props.options.length; i++) {
                    props.options[i].text = props.options[i].text || props.options[i].value
                    props.options[i].input = props.options[i].input || props.options[i].text
                    if (get(that.state, key) === props.options[i].value) {
                        text = props.options[i].input
                    }
                }
                return (
                    <div style={{ position: "relative" }}>
                        <input className="fluid" readOnly value={text} style={props.style}
                            placeholder={props.placeholder} />
                        <Box center
                            style={{
                                position: "absolute", color: "#acacac", right: 0, top: 0,
                                padding: "0 1rem", height: "100%", marginTop: "-0.1em",
                            }}>
                            ▾
                        </Box>
                        <select {...props}
                            style={{
                                position: "absolute",
                                top: 0, left: 0, width: "100%", height: "100%", opacity: 0
                            }} onChange={e => {
                                set(that.state, key, e.target.value)
                                that.setState({})
                            }}>
                            {props.options.map(x =>
                                <option value={x.value}>{x.text}</option>
                            )}
                        </select>
                    </div>
                )
            default:
                return <input {...props} className="fluid"
                    value={get(that.state, key)}
                    onChange={e => {
                        let oldValue = get(that.state, key)
                        set(that.state, key, e.target.value)
                        if (props && props._onChange) {
                            props._onChange(e.target.value, () => set(that.state, key, oldValue === undefined ? "" : oldValue))
                        }
                        that.setState({})
                    }} />
        }
    },

    formSelect(that, key, props) {
        let options = props.options.map(opt => (
            <option value={opt.value}>
                {opt.text || opt.value}
            </option>
        ))
        let select = (
            <select value={get(that.state, key)} style={{ width: "100%", padding: "0 1rem" }}
                onChange={e => {
                    set(that.state, key, e.target.value)
                    that.setState({})
                    if (props._onChange) props._onChange(e)
                }}>
                <option value=""></option>
                {options}
            </select>
        )
        return (
            <span style={{ position: "relative" }}>
                {select}
                {props.placeholder && !get(that.state, key) ?
                    <span style={{ position: "absolute", color: "#999", left: "1rem", top: 2 }}>
                        {props.placeholder}
                    </span>
                    : null
                }
            </span>
        )
    },
}
