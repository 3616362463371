// import '../../../styles/mobile.less'
// import '../../../styles/mobile-ext.less'

import * as React from 'react'

import './Drawer.less'

interface IDrawerProps {
    visible: boolean
    title?: string
    placement: 'top' | 'bottom'
    closable?: boolean
    closePosition?: 'left' | 'right'
    onclose?: (any) => any
    style?: any
}

interface IDrawerState {
    wrapperHeight: number
}

export default class Drawer extends React.Component<IDrawerProps, IDrawerState> {
    constructor(props: IDrawerProps) {
        super(props)
        this.state = {
            wrapperHeight: 0
        }
    }

    componentDidMount() {
        let wrapper = document.querySelector('#root div.scrollBody div.drawer-wrapper') as HTMLElement
        this.setState({wrapperHeight: wrapper.clientHeight})
    }

    render() {
        return (
            <div 
                className={`drawer-wrapper ${this.props.visible && 'show'}`}
                style={{
                    top: this.props.placement === 'top' ? 
                            this.props.visible ?
                            0 
                            : -this.state.wrapperHeight
                        : null,
                    bottom : this.props.placement === 'bottom' ?
                                this.props.visible ? 
                                    0
                                    : -this.state.wrapperHeight
                            : null,
                    ...this.props.style
                }}
            >
                {this.props.children}
            </div>
        )
    }
}