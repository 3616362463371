import * as React from "react"
import "./index.less"

// 菜单
export class MenuIcon extends React.Component<any, any> {
    constructor(props: any) {
        super(props)
    }

    render() {
        let { iStyle, color, onClick, svgHeight } = this.props
        return (
            <i style={iStyle} onClick={() => {
                if(onClick) {
                    onClick()
                }
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" height={svgHeight || "16"} viewBox="0 0 448 512"><path opacity="1" fill={color} d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/></svg>
            </i>
        )
    }
}

// 搜索
export class SearchIcon extends React.Component<any, any> {
    constructor(props: any) {
        super(props)
    }

    render() {
        let { iStyle, color, onClick, svgHeight } = this.props
        return (
            <i style={iStyle} onClick={() => {
                if(onClick) {
                    onClick()
                }
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" height={svgHeight || "16"} viewBox="0 0 512 512">
                    <path opacity="1" fill={color} d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/>
                </svg>
            </i>
        )
    }
}

// 置顶
export class ChevronUpIcon extends React.Component<any, any> {
    constructor(props: any) {
        super(props)
    }

    render() {
        let { iStyle, color, onClick, id, svgHeight } = this.props
        return (
            <i id={id} style={iStyle} onClick={() => {
                if(onClick) {
                    onClick()
                }
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" height={svgHeight || "16"} viewBox="0 0 512 512">
                    <path opacity="1" fill={color} d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"/>
                </svg>
            </i>
        )
    }
}

// 分类向下三角箭头
export class CaretDownIcon extends React.Component<any, any> {
    constructor(props: any) {
        super(props)
    }

    render() {
        let { iStyle, color, svgHeight, onClick } = this.props
        return (
            <i style={iStyle} onClick={() => {
                if(onClick) {
                    onClick()
                }
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" height={svgHeight || "16"} viewBox="0 0 320 512" style={{display: "flex"}}>
                    <path opacity="1" fill={color} d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/>
                </svg>
            </i>
        )
    }
}

// 招聘-其他职位-更多图标
export class AngleRightIcon extends React.Component<any, any> {
    constructor(props: any) {
        super(props)
    }

    render() {
        let { iStyle, color, svgHeight, onClick } = this.props
        return (
            <i style={iStyle} onClick={() => {
                if(onClick) {
                    onClick()
                }
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" height={svgHeight || "16"} viewBox="0 0 320 512" style={{display: "flex"}}>
                    <path fill={color} d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/>
                </svg>
            </i>
        )
    }
}

// 首页
export class HomeIcon extends React.Component<any, any> {
    constructor(props: any) {
        super(props)
    }

    render() {
        let { iStyle, color, svgHeight, onClick } = this.props
        return (
            <i style={iStyle} onClick={() => {
                if(onClick) {
                    onClick()
                }
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" height={ svgHeight || "16"} viewBox="0 0 576 512" style={{display: "flex"}}>
                    <path fill={color} d="M543.8 287.6c17 0 32-14 32-32.1c1-9-3-17-11-24L512 185V64c0-17.7-14.3-32-32-32H448c-17.7 0-32 14.3-32 32v36.7L309.5 7c-6-5-14-7-21-7s-15 1-22 8L10 231.5c-7 7-10 15-10 24c0 18 14 32.1 32 32.1h32v69.7c-.1 .9-.1 1.8-.1 2.8V472c0 22.1 17.9 40 40 40h16c1.2 0 2.4-.1 3.6-.2c1.5 .1 3 .2 4.5 .2H160h24c22.1 0 40-17.9 40-40V448 384c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32v64 24c0 22.1 17.9 40 40 40h24 32.5c1.4 0 2.8 0 4.2-.1c1.1 .1 2.2 .1 3.3 .1h16c22.1 0 40-17.9 40-40V455.8c.3-2.6 .5-5.3 .5-8.1l-.7-160.2h32z"/>
                </svg>
            </i>
        )
    }
}

// 发表
export class Pen2SquareIcon extends React.Component<any, any> {
    constructor(props: any) {
        super(props)
    }

    render() {
        let { iStyle, color, svgHeight, onClick } = this.props
        return (
            <i style={iStyle} onClick={() => {
                if(onClick) {
                    onClick()
                }
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" height={ svgHeight || "16"} viewBox="0 0 512 512" style={{display: "flex"}}>
                    <path fill={color} d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/>
                </svg>
            </i>
        )
    }
}

