import * as React from "react"
import { Box } from "react-polymer-layout"

import * as util from "../util"
import { actions, db$ } from "../../flux"
import { okToast } from "./Toast"

interface Props {
    target: string
    type: "STAR_JOB" | "STAR_THESIS" | "STAR_CAREER" | "STAR_INFORMATION" | "STAR_EVENT"
}

interface State {
    starred: boolean
    toast: string
    session: any
}

export default class Star extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            starred: false,
            toast: "",
            session: null,
        }
    }

    componentDidMount() {
        actions.api.profile.starredTarget(
            { from: 0, size: 1, target: this.props.target },
            () => this.setState({ starred: true }),
            () => { },
        )
        
        this.setState({
            session: db$.login.findOne({ _id: "session" })
        })
    }

    render() {
        let session = this.state.session
        let isVisitor = session && session.id && session.openid && !session.profileId && !session.hrId

        return (
            <Box center style={{ fontSize: 13, color: "#666", whiteSpace: "nowrap" }}
                onClick={() => {
                    if (isVisitor) {
                        location.href = "/join"
                    }
                    if (this.state.starred) {
                        actions.api.profile.unstar(
                            { type: this.props.type, target: this.props.target },
                            () => this.setState({ starred: false, toast: "取消收藏" })
                        )
                    } else {
                        actions.api.profile.star(
                            { type: this.props.type, target: this.props.target, url: location.pathname },
                            () => this.setState({ starred: true, toast: "收藏成功" })
                        )
                    }
                }}>
                收藏
                {util.minWidth(10)}
                <img src={util.iconURL(this.state.starred ? "starred" : "star") + ".svg"}
                    style={{ height: 23, verticalAlign: "bottom" }} />

                {okToast(this.state.toast, () => this.setState({ toast: "" }))}
            </Box>
        )
    }
}
