import * as React from "react"
import * as ReactDOM from "react-dom"
import TextArea from "semantic-ui-react/dist/commonjs/addons/TextArea"
import { Box, Item } from "react-polymer-layout"

import * as util from "../util"
import { actions } from "../../flux"
import { displayScrollTop } from "./elements"
import { Pen2SquareIcon } from "../../components/FontawesomeIcon"

interface Props {
    id: string
    style?: React.CSSProperties
}

interface State {
    popup: number
    comments: any[]
    total: number
    newComment: string
    anonymous: boolean
    session: any
}

export default class Comments extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            popup: 0,
            comments: [],
            total: 0,
            newComment: localStorage && localStorage.getItem("comment") || "",
            anonymous: false,
            session: undefined,
        }
    }

    componentDidMount() {
        actions.login.get(res => {
            this.setState({ session: res })
        }, () => {
            this.setState({ session: null })
        })
        actions.api.thesis.comments({
            id: this.props.id,
            from: 0,
            size: 1000,
        }, res => {
            this.setState({ comments: res.hits, total: res.total })
        })
    }

    componentDidUpdate(_, prevState) {
        if (prevState.popup !== this.state.popup && this.state.popup === 1) {
            let textarea = ReactDOM.findDOMNode(this.refs.textarea)
            if (textarea) (textarea as any).focus()
        }
        displayScrollTop(this.state.popup ? "none" : "block")
    }

    render() {
        let session = this.state.session
        let isVisitor = session && session.id && session.openid && !session.profileId && !session.hrId

        let tip = (
            <Item flex className="text-primary" onClick={() => this.setState({ popup: 1 })}
                style={{ lineHeight: "32px", background: "#f4f4f4", fontSize: 14, paddingLeft: 10, display: "flex", alignItems: "center" }}>
                <Pen2SquareIcon  iStyle={{ marginRight: "0.5em", display: "inline-block" }} color="#290382" svgHeight="14"/>
                发表你的看法
            </Item>
        )

        return (
            <Box center style={{ padding: "12px 15px", background: "white", ...this.props.style }}>
                {tip}
                <Box centerJustified onClick={() => this.setState({ popup: 2 })}
                    style={{
                        backgroundImage: `url(${util.iconURL("comment.svg")})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                        height: 28, width: 28, marginLeft: 15,
                        color: "#d51216", fontSize: 12, lineHeight: "24px", textAlign: "center",
                    }}>
                    {this.state.total > 999 ? "1K+" : (this.state.total || "")}
                </Box>

                {this.state.popup === 2 ?
                    <Box fit end
                        style={{ position: "fixed", background: "rgba(0,0,0,0.6)", zIndex: 2 }}>
                        <Item fit onClick={() => this.setState({ popup: 0 })} />

                        <Box vertical style={{ width: "100%", background: "white", padding: "0 15px 12px", zIndex: 1 }}>
                            <Box center style={{ paddingTop: 15 }}>
                                <b style={{ fontSize: 18 }}>全部评论</b>
                                <Item flex />
                                <img src={util.iconURL("close.svg")} style={{ height: 20 }}
                                    onClick={() => this.setState({ popup: 0 })} />
                            </Box>
                            <Item relative style={{ overflow: "scroll" }}>
                                <div style={{ maxHeight: innerHeight * 0.6 }}>
                                    {this.state.comments.length === 0 ?
                                        <div style={{ padding: "1em 0", color: "#999", fontSize: 12 }}>
                                            还没有人评论，赶紧来抢沙发～
                                        </div>
                                        : null
                                    }
                                    {this.state.comments.map(c =>
                                        session === undefined ? null : <Comment key={c.id} comment={c} session={session} />
                                    )}
                                </div>
                            </Item>
                            {util.minHeight(15)}
                            {tip}
                        </Box>
                    </Box>
                    : null
                }

                {this.state.popup === 1 ?
                    <Box fit end
                        style={{ position: "fixed", background: "rgba(0,0,0,0.6)", zIndex: 2 }}>
                        <Item fit onClick={() => this.setState({ popup: 0 })} />

                        <div style={{ width: "100%", padding: 15, background: "white", zIndex: 1 }}>
                            <TextArea ref="textarea" autoHeight placeholder="留下你的评论吧" value={this.state.newComment}
                                onChange={(_, data: any) => {
                                    this.setState({ newComment: data.value })
                                    if (localStorage) localStorage.setItem("comment", data.value)
                                }}
                                style={{
                                    background: "#f4f4f4", padding: 10, fontSize: 13,
                                    width: "100%", borderRadius: 2, border: "none", lineHeight: 1.6,
                                }} />
                            <div style={{ fontSize: 12, color: "#666", marginTop: 13, textAlign: "right" }}>
                                <i className={`fa fa${this.state.anonymous ? "-check" : ""}-square-o mr05`}
                                    style={{ fontSize: 14, verticalAlign: "text-bottom" }}
                                    onClick={() => this.setState({ anonymous: !this.state.anonymous })} />
                                匿名评论
                            </div>
                            <Box endJustified style={{ marginTop: 15, fontSize: 13 }}>
                                <button style={{ height: 28, width: 60, borderRadius: 999, borderColor: "#666", color: "#666" }}
                                    onClick={() => this.setState({ popup: 0 })}>
                                    取消
                                </button>
                                {util.minWidth(15)}
                                <button style={{ height: 28, width: 60, borderRadius: 999, border: "none", color: "white", background: "#290382" }}
                                    onClick={() => {
                                        if (isVisitor) {
                                            location.href = "/join"
                                        }
                                        actions.api.thesis.comment({
                                            id: this.props.id,
                                            contextId: location.pathname.substr(1),
                                            content: this.state.newComment,
                                            anonymous: this.state.anonymous,
                                        }, () => {
                                            if (localStorage) localStorage.removeItem("comment")
                                            location.reload()
                                        })
                                    }}>
                                    发送
                                </button>
                            </Box>
                        </div>
                    </Box>
                    : null
                }
            </Box>
        )
    }
}

interface CommentProps {
    comment: any
    session: any
}

interface CommentState {
    senderName: string
    senderAvatar: String
}

class Comment extends React.Component<CommentProps, CommentState> {
    constructor(props) {
        super(props)
        this.state = {
            senderAvatar: "",
            senderName: "",
        }
    }

    componentDidMount() {
        let comment = this.props.comment
        if (comment.anonymous && (!this.props.session || comment.senderId !== this.props.session.profileId)) {
            this.setState({
                senderAvatar: "",
                senderName: "匿名用户" + (parseInt(comment.senderId) + 1),
            })
        } else {
            actions.api.nickname.get({
                id: comment.senderId
            }, resp => {
                this.setState({
                    senderAvatar: resp.avatar,
                    senderName: resp.nickname,
                })
            })
        }
    }

    render() {
        return (
            <Box start style={{ paddingTop: 15 }}>
                <div
                    style={{
                        height: 25, width: 25, fontSize: 25, color: "#5ebc95", textAlign: "center",
                        borderRadius: 999,
                        backgroundImage: `url(${this.state.senderAvatar})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                    }}>
                    <i className="fa fa-user-secret" style={{ opacity: this.state.senderAvatar ? 0 : 1 }} />
                </div>
                {util.minWidth(8)}
                <Item flex style={{ paddingBottom: 10, borderBottom: "1px solid #e9e9e9", color: "#333", overflow: "hidden" }}>
                    <Box center justified className="ellipsis" style={{ fontSize: 12, lineHeight: "25px", width: "100%" }}>
                        <div className="ellipsis" style={{ fontWeight: 700 }}>
                            {this.state.senderName}
                        </div>
                        {util.minWidth(15)}
                        <div className="text-minor">
                            {util.shortTimeFormat(this.props.comment.createdAt)}
                        </div>
                    </Box>
                    <div style={{ fontSize: 15, lineHeight: "25px" }}>
                        {this.props.comment.content}
                    </div>
                </Item>
            </Box>
        )
    }
}
