import * as React from 'react';
import { PureComponent } from 'react';

// 定义接口
interface MathJaxRendererProps {
  text: string;
  delay?: number;
}

interface MathJaxWindow extends Window {
  MathJax?: {
    typesetPromise: (elements?: HTMLElement[]) => Promise<void>;
    typesetClear: (elements?: HTMLElement[]) => void;
  };
}

class MathJaxRenderer extends PureComponent<MathJaxRendererProps> {
  private containerRef: HTMLDivElement | null;
  private renderTimer: number | null;

  static defaultProps = {
    delay: 100
  };

  constructor(props: MathJaxRendererProps) {
    super(props);
    this.containerRef = null;
    this.renderTimer = null;
  }

  // 使用旧版本的 ref 回调方式
  private setContainerRef = (element: HTMLDivElement) => {
    this.containerRef = element;
  };

  // 组件挂载时进行初始渲染
  componentDidMount() {
    this.scheduleMathJaxRender();
  }

  // 组件更新时重新渲染
  componentDidUpdate(prevProps: MathJaxRendererProps) {
    if (prevProps.text !== this.props.text) {
      this.scheduleMathJaxRender();
    }
  }

  // 组件卸载时清理定时器
  componentWillUnmount() {
    if (this.renderTimer) {
      window.clearTimeout(this.renderTimer);
    }
  }

  // 使用防抖处理渲染
  private scheduleMathJaxRender = () => {
    if (this.renderTimer) {
      window.clearTimeout(this.renderTimer);
    }

    this.renderTimer = window.setTimeout(() => {
      this.renderMathJax();
    }, this.props.delay);
  };

  // 执行 MathJax 渲染
  private renderMathJax = () => {
    if (!this.containerRef) return;

    const mathJaxWindow = window as MathJaxWindow;
    if (!mathJaxWindow.MathJax) {
      console.warn('MathJax is not loaded');
      return;
    }

    try {
      // 清除之前的渲染
      mathJaxWindow.MathJax.typesetClear([this.containerRef]);
      // 执行新的渲染
      mathJaxWindow.MathJax.typesetPromise([this.containerRef]);
    } catch (error) {
      console.error('MathJax rendering failed:', error);
    }
  };

  render() {
    const { text } = this.props;

    return (
        <div
            ref={this.setContainerRef}
            dangerouslySetInnerHTML={{ __html: text }}
        />
    );
  }
}

export default MathJaxRenderer;
