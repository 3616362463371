import * as React from 'react'

import './Popover.less'

interface IProps {
    visible: boolean
    style?: any
    children?: any
}

export default (props: IProps) => {
    return (
        props.visible ?
            <div 
                className={'popover-wrapper'}
                style={{
                    ...props.style
                }}>
                    <div
                        className={'popover-children'}>
                        {props.children}
                    </div>
            </div>
        : null
    )
}