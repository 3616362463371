import * as React from "react"
import * as ReactDOM from "react-dom"
import {Box, Item} from "react-polymer-layout"

import * as elements from "./elements"
import * as util from "../util"
import * as mockxhr from "mockxhr"
import SearchPopup from "./SearchPopup"
import {MenuIcon, SearchIcon, HomeIcon} from "../../components/FontawesomeIcon"
import {actions} from "../../flux"
import * as qs from "query-string"

interface Props {
    selectMenu: string
    homeButton?: boolean
    title?: any
    backgroundColor?: string
    zonBgColor?: string
    tabs?: any
    height?: number
    onScrollAction?: any
    getShowStatus?: any
    elsevierHeadName?: string
    elsevierHeadLink?: string
}

interface State {
    maskHeight: number
    scrollTop: number
    session: any
    showHeader: boolean
    showMenu: boolean
    showScrollToTop: boolean
    innerWidth: number
}

export default class HeaderMenu extends React.Component<Props, State> {
    height = this.props.height || 45

    constructor(props) {
        super(props)
        this.state = {
            maskHeight: 0,
            scrollTop: 0,
            session: null,
            showHeader: true,
            showMenu: false,
            showScrollToTop: false,
            innerWidth: window.innerWidth,
        }
    }

    componentDidMount() {
        let body = ReactDOM.findDOMNode(this.refs.scrollBody) as HTMLElement
        if (body) body.addEventListener("scroll", (e: any) => {
            const scrollTop = e.target.scrollTop
            this.setState({
                showScrollToTop: true,
                scrollTop: scrollTop,
                showHeader: scrollTop < this.height || scrollTop < this.state.scrollTop,
            })
            if (this.props.onScrollAction) this.props.onScrollAction(e.target.scrollTop)
        })

        window.addEventListener("click", e => {
            if (this.refs && this.refs.menu && e.target) {
                let menu = ReactDOM.findDOMNode(this.refs.menu)
                if (!menu.contains(e.target as any)) this.setState({showMenu: false})
            }
        })

        actions.login.get(session => this.setState({session}))

        window.addEventListener("resize", () => {
            this.setState({
                innerWidth,
            })
        })
    }

    menu(i, text, link, style?) {
        return (
            <a key={i} href={link}>
                <Box center centerJustified
                     style={{
                         height: 45, color: "white", fontSize: 17,
                         background: this.props.selectMenu === i ? "#464850" : "none",
                         borderTop: this.props.selectMenu === i ? "1px solid #999" : "none",
                         borderBottom: this.props.selectMenu === i ? "1px solid #999" : "none",
                         ...style
                     }}>
                    {text}
                </Box>
            </a>
        )
    }

    render() {
        const menuWidth = 130
        let {session, innerWidth} = this.state
        let isVisitor = session && session.id && session.openid && !session.profileId && !session.hrId
        let {elsevierHeadName, elsevierHeadLink} = this.props

        let top = this.menu("", "登录 / 注册", "/login?redirect=" + encodeURIComponent(location.href), {height: 56})

        if (this.state.session && (this.state.session.profileId || this.state.session.hrId)) {
            top = this.menu("", this.state.session.phoneNumber, "#", {height: 47, fontSize: 14})
        }
        if (isVisitor) {
            top = (
                <div>
                    {this.menu("", "登录", `/logout?redirect=${encodeURIComponent(location.origin)}/login`)}
                    {this.menu("", "注册", "/join")}
                </div>
            )
        }
        const params = qs.parse(location.search)

        return (
            <div className="transition-500"
                 style={{
                     width: menuWidth + window.innerWidth, height: document.body.clientHeight, overflowX: "hidden",
                     marginLeft: this.state.showMenu ? 0 : -menuWidth,
                     overflowY: this.state.showMenu ? "hidden" : "auto",
                 }}>

                <SearchPopup ref="search"/>

                <div ref="menu"
                     style={{
                         display: "inline-block", width: menuWidth, background: "#333", height: "100%",
                         verticalAlign: "top", overflow: "auto",
                     }}>
                    {util.minHeight(45)}
                    {top}
                    {this.menu("index", "首页", "/")}
                    {this.menu("theses", "论文", "/theses")}
                    {this.menu("periodical", "期刊", "/periodical")}
                    {this.menu("information", "新闻", "/information")}
                    {this.menu("jobs", "招聘", "/search")}
                    {this.menu("careers", "生涯", "/careers")}
                    {/*{this.menu("meetings", "会议", "/meetings")}*/}
                    {util.minHeight(8)}
                    {this.menu("starred", "我的收藏", "/starred")}
                    {this.state.session && (this.state.session.profileId || this.state.session.hrId) ? [
                        this.menu("account", "帐号设置", this.state.session.profileId ? "/profile/account" : "/hr/account"),
                        this.menu("", "退出", "/logout?redirect=" + encodeURIComponent(location.href))
                    ] : null}
                    {util.minHeight(14)}
                </div>

                <Item relative ref="scrollBody" className="scrollBody" id="scrollBody"
                      style={{
                          display: "inline-block",
                          width: innerWidth,
                          height: innerHeight,
                          verticalAlign: "top",
                          // overflow: "auto",
                          overflowX: 'hidden',
                          overflowY: 'auto'
                      }}>
                    {util.minHeight(this.height)}
                    <div
                        style={{
                            // position: "fixed", top: this.state.showHeader ? 0 : -this.height, zIndex: 10,
                            position: "fixed", top: 0, zIndex: 20,
                            transition: "top 200ms", width: "100%",
                        }}>
                        <Box center justified
                             style={{
                                 height: 45, width: "100%", fontSize: 15, color: "white",
                                 background: this.props.backgroundColor || "#290382",
                             }}>
                            <MenuIcon iStyle={{display: "inline-block", padding: 15, fontSize: 15, lineHeight: 1}}
                                      color="#fff"
                                      onClick={() => {
                                          let body = ReactDOM.findDOMNode(this.refs.scrollBody) as HTMLElement
                                          if (body) this.setState({maskHeight: body.scrollHeight})
                                          setTimeout(() => {
                                              this.setState({showMenu: true}, () => {
                                                  if (this.props.getShowStatus) this.props.getShowStatus(this.state.showMenu)
                                              })
                                          }, 100)
                                      }}
                            />
                            {this.props.homeButton ?
                                [
                                    <div style={{overflow: "hidden"}}>
                                        {util.logo({
                                            width: 64,
                                            marginLeft: -26,
                                            verticalAlign: "text-bottom"
                                        }, "-light")}
                                    </div>
                                    ,
                                    <Item flex/>
                                    ,
                                    <div style={{display: "flex", alignItems: "center"}}
                                         onClick={() => location.href = "/"}>
                                        <HomeIcon iStyle={{
                                            padding: "15px 7px 15px 15px",
                                            lineHeight: 1,
                                            display: "inline-block"
                                        }} color="#fff" svgHeight="14"/>
                                        首页
                                    </div>
                                    ,
                                    util.minWidth(5),
                                    <div onClick={() => (this.refs.search as SearchPopup).show()}>
                                        <SearchIcon iStyle={{
                                            display: "inline-block",
                                            padding: "15px 7px 15px 15px",
                                            lineHeight: 1
                                        }} color="#fff"/>
                                        搜索
                                    </div>
                                    ,
                                    util.minWidth(15)
                                ] : [
                                    this.props.title || util.logo({height: 28}, "-light"),
                                    <SearchIcon
                                        iStyle={{display: "inline-block", padding: 15, fontSize: 15, lineHeight: 1}}
                                        color="#fff"
                                        onClick={() => (this.refs.search as SearchPopup).show()}
                                    />
                                ]
                            }
                        </Box>
                        {this.props.tabs}
                    </div>
                    <div className="elsevier-container" style={{
                        display: elsevierHeadName ? "flex" : "none",
                        top: this.state.showHeader ? "45px" : 0,
                        transition: "top 200ms"
                    }}>
                        <p className="elsevier-list-container">{elsevierHeadName}</p>
                        <button className="elsevier-click-container" onClick={() => {
                            if (!elsevierHeadLink) return
                            mockxhr.POST(`/api/push/jump/page/stat`, {
                                pushBatchNo: params.pushBatchNo,
                                uniqueId: params.uniqueId,
                                type: 3
                            }, res => {
                            })
                            window.location.href = elsevierHeadLink
                        }}>点击查看
                        </button>
                    </div>
                    <Box vertical style={{minHeight: innerHeight - this.height, background: this.props.zonBgColor}}>
                        {this.props.children}
                    </Box>

                    {this.state.showMenu ?
                        <Item fit style={{background: "rgba(0,0,0,0.6)", height: this.state.maskHeight, zIndex: 20}}
                              onClick={() => this.setState({showMenu: false}, () => {
                                  if (this.props.getShowStatus) this.props.getShowStatus(this.state.showMenu)
                              })}/>
                        : null
                    }
                </Item>

                {this.state.showScrollToTop ? elements.scrollToTop("#root .scrollBody", this.props.backgroundColor) : null}
            </div>
        )
    }
}
