import * as React from "react"
import * as ReactDOM from "react-dom"
import * as mockxhr from "mockxhr"
import { Box, Item } from "react-polymer-layout"
import { get } from "object-path"

import * as util from "../util"
import { API } from "../../flux"

interface Props {
    job: any
    onClick?: any
}

interface State {
    employer: any
    width: number
}

export default class JobItem extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            employer: {},
            width: 0,
        }
    }

    componentDidMount() {
        mockxhr.GET(API.employer.one(this.props.job.employerId), {}, resp => {
            this.setState({ employer: resp })
        })

        let line = ReactDOM.findDOMNode(this.refs.line)
        if (line) {
            this.setState({
                width: line.getBoundingClientRect().width,
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (get(nextProps, "job.id") !== get(this.props, "job.id")) {
            this.setState({}, () => this.componentDidMount())
        }
    }

    render() {
        let job = this.props.job
        return (
            <div id={job.id} style={{ background: "white", padding: ".8rem 1rem" }}
                onClick={() => {
                    if (this.props.onClick) this.props.onClick()
                    location.href = `/jobs/${job.id}`
                }}>

                <Box style={{ width: this.state.width, overflowX: "hidden", maxWidth: this.state.width }}>
                    <span className="border-color-2">
                        {util.imageCenter(this.state.employer.logo, { height: "2.8rem", minWidth: "2.8rem" })}
                    </span>
                    {util.minWidth("1rem")}
                    <Item flex relative>
                        <Box vertical fit centerJustified style={{ width: "100%" }}>
                            <div className="ellipsis" style={{ fontSize: ".9rem", fontWeight: 700, lineHeight: 1.2 }}>
                                {util.fullEmployerName(this.state.employer.name, job.department)}
                            </div>
                            {util.minHeight("1rem")}
                            <div className="ellipsis text-primary" style={{ fontSize: ".8rem" }}>
                                {util.jobNameWithTalentPlans(job.name, job.talentPlans)}
                            </div>
                        </Box>
                    </Item>
                </Box>

                <div ref="line" className="bg-color-2" style={{ margin: ".8rem 0", height: 1 }} />

                <div className="text-gray" style={{ fontSize: "0.8rem", lineHeight: 1.666, margin: "-0.333em 0" }}>
                    {util.researchFields(job)}
                </div>
                {util.minHeight(".8rem")}
                <Box className="text-gray" style={{ fontSize: ".8rem" }}>
                    <span className="nowrap">
                        {util.salaryText(job)}
                    </span>
                    {util.minWidth("1em")}
                    <div className="ellipsis-max">
                        {job.city}
                    </div>
                    {util.minWidth("1em")}
                    <div className="nowrap">
                        {job.minDegree}
                    </div>
                    <Item flex />
                    <div className="text-minor" style={{ whiteSpace: "nowrap" }}>
                        {util.formatTs(job.onlineTime, "%Y/%m/%d")}
                    </div>
                </Box>
            </div>
        )
    }
}

export class JobItem2 extends React.Component<Props, State> {
    static employers = {}

    constructor(props) {
        super(props)
        this.state = {
            employer: {},
            width: 0,
        }
    }

    componentDidMount() {
        setInterval(() => this.setState({}), 1000)
        let employerId = this.props.job.employerId
        if (JobItem2.employers.hasOwnProperty(employerId)) return
        mockxhr.GET(API.employer.one(employerId), {}, res => {
            this.setState({ employer: res })
            JobItem2.employers[employerId] = res
        })
    }

    render() {
        let job = this.props.job
        let employer = JobItem2.employers[job.employerId] || {}
        return (
            <div style={{ margin: "15px 15px 0", background: "#f8f8f8" }}
                onClick={() => location.href = "/jobs/" + job.id}>
                <Box style={{ padding: 12 }}>
                    <div style={{ background: "#fff", border: "0.75px solid #eee" }}>
                        {util.imageContain(employer.logo, 45)}
                    </div>
                    {util.minWidth(12)}
                    <Item flex relative>
                        <Item fit>
                            <div className="ellipsis" style={{ fontSize: 15 }}>
                                {util.fullEmployerName(job.employerName, job.department)}
                            </div>
                            {util.minHeight(15)}
                            <div className="ellipsis text-primary" style={{ fontSize: 15 }}>
                                {job.name}
                            </div>
                        </Item>
                    </Item>
                </Box>
                <div style={{ color: "#999", fontSize: 12, lineHeight: "18px", padding: "0 12px" }}>
                    {util.researchFields(job)}
                </div>
                {util.line("#eee", 0.75, { margin: "9px 12px", width: "auto" })}
                <Box center style={{ fontSize: 12, padding: "0 12px 9px", color: "#999" }}>
                    <div>
                        {util.salaryText(job)}
                    </div>
                    {util.minWidth(24)}
                    <Item flex relative style={{ height: "1em" }}>
                        <Item fit className="ellipsis">{job.city}</Item>
                    </Item>
                    {util.minWidth(24)}
                    <div>{job.updatedAt + 86400 * 30 * 1000 > Date.now() ? "最新" : "最近"}</div>
                </Box>
            </div>
        )
    }
}

export class JobItem3 extends React.Component<Props, State> {
    static employers = {}

    constructor(props) {
        super(props)
        this.state = {
            employer: {},
            width: 0,
        }
    }

    componentDidMount() {
        if (!this.props.job) return
        let employerId = this.props.job.employerId
        if (JobItem3.employers.hasOwnProperty(employerId)) return
        mockxhr.GET(API.employer.one(employerId), {}, res => {
            this.setState({ employer: res })
            JobItem3.employers[employerId] = res
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps && get(prevProps, "job.id") !== get(this.props, "job.id")) {
            this.componentDidMount()
        }
    }

    render() {
        let job = this.props.job
        if (!job) return <div></div>
        let employer = JobItem3.employers[job.employerId]
        if (!employer) return <div></div>
        return (
            <Box center style={{ marginBottom: 10 }} onClick={() => location.href = "/jobs/" + job.id}>
                <Item flex>
                    <div style={{ height: 40, fontSize: 12, lineHeight: "20px", overflow: "hidden" }}>
                        {util.fullEmployerName(employer.name || job.employerName, job.department)}
                        招聘
                        {job.name}
                    </div>
                </Item>
                {util.minWidth(10)}
                {util.imageContain(employer.logo, 43, { border: "1px solid #e7e7e7" })}
            </Box>
        )
    }
}
