import * as React from "react"
import { Box, Item } from "react-polymer-layout"
import { logo, iconURL } from "../../util/index"

export * from "../../util/index"

export function backIcon(style?) {
    let onClick = () => {
        if (document.referrer === "" || history.length === 1) {
            location.href = "/"
        } else {
            history.back()
        }
    }
    return <i className="fa fa-chevron-left text-primary" style={style} onClick={onClick} />
}

export function detailHeader(title) {
    return (
        <Box center justified className="bg-color-primary" style={{ height: "3rem", zIndex: 1 }}>
            {backIcon({ color: "white", padding: "1rem", cursor: "pointer" })}
            <div style={{ fontSize: "1.1rem" }}>
                {title}
            </div>
            <div style={{ minWidth: "3rem" }} />
        </Box>
    )
}

export function detailHeader2(buttonText: string, link: string) {
    return (
        <Box center style={{ height: "3rem", zIndex: 1, boxShadow: "0 2px 4px rgba(0,0,0,0.1)", padding: "0 1rem" }}>
            {logo({ height: "2rem" })}
            <Item flex />
            <a href={link}>
                <div
                    style={{
                        fontSize: ".9rem", background: "#0071fe", color: "white",
                        lineHeight: 1.85, padding: "0 1em", borderRadius: 999
                    }}>
                    <img src={iconURL("search-file.svg")} style={{ height: "1.2em", verticalAlign: "text-bottom", marginRight: ".25em" }} />
                    {buttonText}
                </div>
            </a>
        </Box>
    )
}

export function isWechatBrowser() {
    return /micromessenger/i.test(navigator.userAgent)
}

export function prependHistory(path = "/") {
    if (typeof document !== "undefined" && !document.referrer && history.go) {
        let currentState = location.pathname + location.search
        history.replaceState(null, null as any, path)
        history.pushState(null, null as any, currentState)
        history.go(1)
        window.addEventListener("popstate", () => {
            if (location.pathname + location.search !== currentState) {
                location.reload()
            }
        })
    }
}

export function round(number: number, precision: number) {
    return Math.round(Number(+number + 'e' + precision)) / Math.pow(10, precision);
}

// 网站默认作品集声明
export const getPortfolioStatement = () => "未经许可请勿转载，获得授权请联系 contact@linkresearcher.com 或致电 010-85325984。"

// 网站默认作品集来源声明
export const getPortfolioSourceStatement = sourceText => `本文转载自“${ sourceText }”。凡本网站转载、引用的文章、图片、音频、视频文件等资料的版权归版权所有人所有，如因此产生相关后果，将由版权所有人、原始发布者和内容提供者承担，如有侵权请尽快联系删除。`
