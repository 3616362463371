import * as React from "react"
import { Box } from "react-polymer-layout"

import * as util from "../util"
import Modal from "./Modal"

interface State {
    title: any
    content: any
    confirmText?: string
    cancelText?: string
    onConfirm?: any
    style?: any
    scrollContent?: boolean
}

export function toast(that, state: State) {
    that.refs.toast.toast(state)
}

export default class Toast extends React.Component<{}, State> {
    constructor(props) {
        super(props)
        this.state = {
            title: "",
            content: "",
            confirmText: "",
            cancelText: "",
            onConfirm: null,
            style: undefined,
            scrollContent: undefined,
        }
    }

    toast({ title, content, confirmText, cancelText, onConfirm, style, scrollContent }) {
        this.setState({
            title,
            content,
            confirmText,
            cancelText,
            onConfirm,
            style,
            scrollContent,
        }, () => (this.refs as any).modal.show())
    }

    render() {
        let { title, content, confirmText, cancelText, onConfirm, style, scrollContent } = this.state
        return (
            <Modal ref="modal" title={title} onConfirm={onConfirm} scrollContent={scrollContent}
                confirmText={confirmText} cancelText={cancelText}
                content={
                    <Box center centerJustified style={{ minHeight: "3rem", lineHeight: 1.6 }}>
                        <div className="text-center text-gray" style={{ fontSize: "1rem", ...style }}>
                            {content}
                        </div>
                    </Box>
                } />
        )
    }
}

export function okToast(message: string, dismiss: any) {
    if (message) setTimeout(() => dismiss(), 2000)
    return (
        <div
            style={{
                background: "rgba(17, 17, 17, 0.7)",
                borderRadius: 5,
                zIndex: 100, position: "fixed",
                width: "6rem", height: "6rem",
                top: "50%", left: "50%",
                marginTop: "-3rem", marginLeft: "-3rem",
                textAlign: "center",
                color: "white", fontSize: "1rem",
                display: message ? "block" : "none",
            }}>
            <img src={util.iconURL("success.svg")} style={{ height: "3rem", margin: "0.5rem 0" }} />
            <div>{message}</div>
        </div>
    )
}
