import * as React from "react"
import * as mockxhr from "mockxhr"
import { Box, Item } from "react-polymer-layout"

import * as util from "../util"

interface Props {
    relevant: any[]
}

export default class Relevant extends React.Component<Props, {}> {
    render() {
        let { relevant } = this.props
        if (!relevant || !relevant.length || !relevant.some(r => r.id)) return null

        let that = this
        relevant.filter(r => r.id && r.timestamp === 0).forEach(r => {
            r.timestamp = Date.now()
            let url = (r.source || "")
                .replace("/careers", "/information")
                .replace("/lectures", "/meetings")
                .replace("/trainings", "/meetings")
                .replace("/lives", "/meetings")
                .replace("/studytours", "/meetings")
                .replace("/contests", "/meetings")
            mockxhr.GET("/api" + url, {}, res => {
                r.onlineTime = res.onlineTime
                r.cover = res.cover || res.wxShareImage
                that.setState({})
            })
        })

        return (
            <div style={{ marginTop: 12 }}>
                <div style={{ padding: "0 15px", textAlign: "justify", background: "white" }}>
                    <div style={{ fontWeight: 700, fontSize: 17, padding: "20px 0 5px" }}>
                        相关推荐
                    </div>
                    {relevant.filter(r => r.id).map((r, i) =>
                        <Box key={r.id} style={{ padding: "15px 0", borderTop: `1px solid ${i > 0 ? "#eee" : "white"}` }}
                            onClick={() => location.href = r.source}>
                            <Box vertical flex style={{ paddingRight: 15, overflow: "hidden" }}>
                                <div style={{ fontSize: 15, lineHeight: "23px", overflow: "hidden", maxHeight: 42 }}>
                                    {r.target}
                                </div>
                                <Item flex />
                                <div className="text-minor ellipsis" style={{ fontSize: 12, textAlign: "right" }}>
                                    {util.formatTs(r.onlineTime)}
                                </div>
                            </Box>
                            {r.type === "JOB" ?
                                util.imageContain(r.cover, 114, { height: 75 })
                                :
                                util.imageCover(r.cover, 114, { height: 75 })
                            }
                        </Box>
                    )}
                </div>
            </div>
        )
    }
}
