import * as React from "react"
import * as mockxhr from "mockxhr"
import * as qs from "query-string"
import { Box, Item } from "react-polymer-layout"
import { set, get } from "object-path"

import * as magazine from "../../misc/magzine"
import * as util from "../util"
import JobItem from "./JobItem"
import LoginMenu from "./LoginMenu"
import { actions } from "../../flux"
import { CaretDownIcon } from "../../components/FontawesomeIcon"
import * as _ from "lodash"

import "../styles/SearchPage.less"

interface Props {
    query?: string
    noHeader?: boolean
    searchSubjects?: string[]
    adList?: any[]
}

interface State {
    query: string
    filters: any
    _filters: any
    from: number
    size: number
    jobs: any[]
    tab: number
    filterEmployer: string
    filterLocation: string
    type: string
    filterCities: any[]
    searchStopped: boolean
    searchEnd: boolean
    magazine: any
    _timestamp: number
}

export default class View extends React.Component<Props, State> {
    readonly STORAGE_STATE_JOB = "mobile:Job"

    constructor(props: Props) {
        super(props)
        let params = qs.parse(location.search)
        this.state = {
            // query: this.props.query || "",
            query: "",
            filters: {},
            _filters: {},
            from: 0,
            size: 20,
            jobs: [],
            tab: 0,
            filterEmployer: "",
            filterLocation: "",
            type: "LATEST",
            filterCities: [],
            searchStopped: true,
            searchEnd: false,
            magazine: {
                issue: get(params, "magazine.issue"),
                jobs: [],
            },
            _timestamp: Date.now(),
        }
        Object.keys(params).forEach(key => {
            // 微信转发会添加 from=singlemessage/timeline/...
            if (key === "from" && isNaN(params[key])) return
            set(this.state, key, decodeURIComponent(params[key]))
        })
    }

    componentDidMount() {
        mockxhr.GET("/api/promotions/searchFilters", {}, resp => {
            resp.forEach(x => {
                set(this.state._filters, `${x.type1}.${x.type2}.${x.title}`, x)
                set(this.state._filters, `undefined.${x.type2}.${x.title}`, x)
            })
            this.setState({})
        })

        const configSize = sessionStorage.getItem("linkresearcher:configSize")
        if(configSize) {
            const configSizeNum = parseInt(configSize)
            this.search(false, configSizeNum)
        } else {
            this.search()
        }

        if (this.state.magazine.issue && magazine.issues[this.state.magazine.issue]) {
            let { jobs } = magazine.issues[this.state.magazine.issue]
            jobs.forEach((id, i) => {
                actions.api.job.get({ id }, res => {
                    this.state.magazine.jobs[i] = res
                    this.setState({})
                })
            })
        }
        
    }
    

    searching = 0
    search(isMore = false, configSize = this.state.size) {
        this.searching = Date.now()
        this.setState({ searchStopped: false })
        if(!isMore) {
            set(this.state, "filters.onlineTime", null)
            actions.api.job.search({
                from: 0,
                size: configSize,
                filters: this.state.filters,
                query: this.state.query,
                type: "LATEST",
                sort: [],
            }, resp => {
                // 初次加载
                let newFrom = 0
                let _resp = resp.hits
                if (this.props.adList && this.props.adList.length > 0) {
                    if (_.filter(this.props.adList, i => i.type1 === `INDEX_CHANNEL_JOB_0`)[0]) {
                        _resp.splice(5, 0, _.filter(this.props.adList, i => i.type1 === `INDEX_CHANNEL_JOB_0`)[0])
                    }
                    if (_.filter(this.props.adList, i => i.type1 === `INDEX_CHANNEL_JOB_1`)[0]) {
                        _resp.splice(16, 0, _.filter(this.props.adList, i => i.type1 === `INDEX_CHANNEL_JOB_1`)[0])
                    }
                }
                const isSearchEnd = resp.hits.length < this.state.size || newFrom >= resp.total
                this.setState({
                    searchStopped: true,
                    searchEnd: isSearchEnd,
                    from: resp.hits.length,
                    jobs: _resp,
                })
            })
        } else {
            // 点查看更多的时候
            set(this.state, "filters.onlineTime", this.state.jobs[this.state.jobs.length - 1].onlineTime)
            actions.api.job.search({
                from: this.state.from,
                size: this.state.size,
                filters: this.state.filters,
                query: this.state.query,
                type: "LATEST",
                sort: [],
            }, resp => {
                let newFrom = this.state.from
                let _resp = resp.hits
                if (this.props.adList && this.props.adList.length > 0) {
                    if (_.filter(this.props.adList, i => i.type1 === `INDEX_CHANNEL_JOB_0`)[0]) {
                        _resp.splice(5, 0, _.filter(this.props.adList, i => i.type1 === `INDEX_CHANNEL_JOB_0`)[0])
                    }
                    if (_.filter(this.props.adList, i => i.type1 === `INDEX_CHANNEL_JOB_1`)[0]) {
                        _resp.splice(16, 0, _.filter(this.props.adList, i => i.type1 === `INDEX_CHANNEL_JOB_1`)[0])
                    }
                }
                const isSearchEnd = resp.hits.length < this.state.size
                this.setState({
                    searchStopped: true,
                    searchEnd: isSearchEnd,
                    from: newFrom + resp.hits.length,
                    jobs: util.mergeArrayById(this.state.jobs, _resp),
                })
            })
        }
        
    }

    tab(i, title) {
        let selected = this.state.tab === i
        return (
            <Box flex centerJustified className={selected ? "text-primary" : "text-minor"}
                onClick={() => this.setState({ tab: i })}>
                {title}
                <CaretDownIcon iStyle={{ marginLeft: "0.5em", display: "flex", alignItems: "center" }} svgHeight="10.67" color="#999" />
            </Box>
        )
    }

    filter(title, key, options, onClick?) {
        return (
            <div className="text-minor" style={{ fontSize: ".8rem", padding: ".5rem" }}>
                <div style={{ padding: ".5rem", fontWeight: 700 }}>
                    {title}
                </div>
                <div>
                    <div className={"text-center " + (!this.state.filters[key] ? "bg-color-primary" : "")}
                        style={{
                            display: "inline-block", minWidth: "20%", lineHeight: "2em",
                            border: "1px solid #e5e5e5", borderRadius: 2, margin: ".5rem", padding: "0 .5em"
                        }}
                        onClick={() => {
                            delete this.state.filters[key]
                            if (onClick) onClick("全部")
                            this.setState({})
                        }}>
                        全部
                    </div>
                    {options.map(opt => {
                        let selected = this.state.filters[key] === opt.value
                        return (
                            <div className={"text-center " + (selected ? "bg-color-primary" : "")}
                                style={{
                                    display: "inline-block", minWidth: "20%", lineHeight: "2em",
                                    border: "1px solid #e5e5e5", borderRadius: 2, margin: ".5rem", padding: "0 .5em"
                                }}
                                onClick={() => {
                                    if (opt.promotionId) setTimeout(() => actions.clickPromotion(opt.promotionId), 0)
                                    this.state.filters[key] = opt.value
                                    if (onClick) onClick(opt.text || opt.value)
                                    this.setState({})
                                }}>
                                {opt.text || opt.value}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    getOptions(group) {
        return Object.keys(group).map(key => {
            return {
                text: key,
                value: key,
                promotionId: group[key].id,
            }
        })
    }

    render() {
        let confirmFilter = (
            <div style={{ padding: "1rem" }}>
                <button className="bg-color-primary"
                    style={{ height: "3rem", width: "100%", fontSize: "1rem" }}
                    onClick={() => this.setState({ tab: 0, jobs: [], from: 0 }, () => this.search())}>
                    确定
                </button>
            </div>
        )

        let employerType = get(this.state, "filters.employerType")

        return (
            <div style={{ minHeight: window.innerHeight, background: "#f2f2f2" }}>
                {this.props.noHeader ? null :
                    <Box center style={{ height: "4rem", fontSize: "1.1rem" }}>
                        {util.minWidth("1rem")}
                        {util.backIcon()}
                        {util.minWidth("1rem")}
                        <Item flex relative style={{ fontSize: ".8rem" }}>
                            <Box center
                                style={{
                                    right: 0,
                                    position: "absolute",
                                    height: "3em",
                                }}>
                                <i className="fa fa-search text-primary"
                                    style={{ fontSize: "1rem", marginRight: ".7em", marginBottom: ".2em" }}
                                    onClick={() => {
                                        this.setState({ from: 0, jobs: [] }, () => this.search())
                                    }} />
                            </Box>
                            <input style={{ borderRadius: 999, background: "white", width: "100%", padding: "0 2.5em 0 1rem" }}
                                value={this.state.query}
                                onChange={e => {
                                    this.setState({ query: e.target.value, from: 0, jobs: [] }, () => this.search())
                                }} />
                        </Item>
                        {util.minWidth("1rem")}
                        <LoginMenu />
                        {util.minWidth("1rem")}
                    </Box>
                }

                <Box relative style={{ fontSize: ".8rem", padding: "1em", background: "white" }}>
                    {this.tab(1, this.state.filterEmployer || "单位筛选")}
                    <div className="bg-color-2" style={{ width: 1, height: "1em" }} />
                    {this.tab(2, "职位筛选")}
                    <div className="bg-color-2" style={{ width: 1, height: "1em" }} />
                    {this.tab(3, this.state.filterLocation || "地点筛选")}
                </Box>
                <div className="bg-color-2" style={{ height: 1 }} />

                <Item flex relative style={{ background: "#f2f2f2" }}>
                    {this.state.tab === 1 ? (
                        <div style={{ position: "absolute", background: "white", zIndex: 2, width: "100%" }}>
                            {this.filter("单位类型", "employerType", [
                                { value: "COLLEGE", text: "高校院所" },
                                { value: "OTHER", text: "企业" },
                            ], text => {
                                if (text !== "高校院所") {
                                    delete this.state.filters.subjects
                                    delete this.state.filters.name
                                    delete this.state.filters.talentPlans
                                }
                                if (text !== "企业") {
                                    delete this.state.filters.industries
                                    delete this.state.filters.classification
                                }
                                this.setState({ filterEmployer: text })
                            })}

                            {this.state.filters.employerType === "COLLEGE" ?
                                this.filter("所属学科", "subjects",
                                    // this.getOptions(get(this.state._filters, "COLLEGE.subjects", {})),
                                    this.props.searchSubjects ? this.props.searchSubjects.map(i => ({value: i, text: i})) : [{}],
                                    text => {
                                        delete this.state.filters.industries
                                        this.setState({ filterEmployer: text })
                                    }
                                )
                                : null
                            }
                            {this.state.filters.employerType === "OTHER" ?
                                this.filter("所属行业", "industries",
                                    this.getOptions(get(this.state._filters, "OTHER.industries", {})),
                                    text => {
                                        delete this.state.filters.subjects
                                        this.setState({ filterEmployer: text })
                                    }
                                )
                                : null
                            }

                            {confirmFilter}
                        </div>
                    ) : null}

                    {this.state.tab === 2 ? (
                        <div style={{ position: "absolute", background: "white", zIndex: 2, width: "100%" }}>
                            {this.filter("工作经验", "experience",
                                this.getOptions(get(this.state._filters, employerType + ".experience", {})),
                            )}
                            {this.filter("学历要求", "minDegree",
                                this.getOptions(get(this.state._filters, employerType + ".minDegree", {})),
                            )}
                            {this.filter("职位性质", "form",
                                this.getOptions(get(this.state._filters, employerType + ".form", {})),
                            )}
                            {employerType === "COLLEGE" ? this.filter("岗位名称", "name",
                                this.getOptions(get(this.state._filters, employerType + ".name", {})),
                            ) : null}
                            {employerType === "COLLEGE" ? this.filter("人才计划", "talentPlans",
                                this.getOptions(get(this.state._filters, employerType + ".talentPlans", {})),
                            ) : null}
                            {employerType === "OTHER" ? this.filter("岗位类型", "classification",
                                this.getOptions(get(this.state._filters, employerType + ".classification", {})),
                            ) : null}

                            {confirmFilter}
                        </div>
                    ) : null}

                    {this.state.tab === 3 ? (
                        <div style={{ position: "absolute", background: "white", zIndex: 2, width: "100%" }}>
                            {this.filter("工作地点", "region",
                                this.getOptions(get(this.state._filters, employerType + ".region", {})),
                                text => {
                                    delete this.state.filters.city
                                    this.setState({ filterLocation: text })
                                    mockxhr.GET(`/api/promotions/searchFilters/${employerType}/region/${text}`, {}, resp => {
                                        // TODO 如果单位类型是“全部”，这里的城市会重复出现
                                        let cities = {}
                                        resp.forEach(x => cities[x.title] = cities[x.title] || x)
                                        this.setState({
                                            filterCities: Object.keys(cities).map(k => cities[k]).sort((a, b) => b.weight - a.weight)
                                        })
                                    })
                                }
                            )}
                            {this.state.filterCities.length ? (
                                this.filter("城市", "city",
                                    this.state.filterCities.map(x => ({ value: x.title, text: x.title, promotionId: x.id })),
                                    text => this.setState({ filterLocation: text })
                                )
                            ) : null}

                            {confirmFilter}
                        </div>
                    ) : null}

                    {[...this.state.magazine.jobs, ...this.state.jobs].filter(job => !!job).map(job => {
                        return job.type === 'AD'
                        ? [
                            util.minHeight(".8rem"),
                            <div className='job-ad-wrapper' onClick={() => window.open(job.link)}>
                                <div className="job-ad-content">
                                    {job.styleType === 'long'
                                        ? <div className='job-ad-long'>
                                            <div className="job-ad-image">
                                                <img src={job.image} />
                                            </div>
                                            <div className="job-ad-bottom">
                                                <span className="icon">推广</span>
                                            </div>
                                        </div>
                                        : job.styleType === 'short'
                                        ? <div className='job-ad-short'>
                                            <div className="job-ad-top">
                                                <div className="job-ad-image">
                                                    <img src={job.image} />
                                                </div>
                                                <div className="job-ad-title">
                                                    {job.title}
                                                </div>
                                            </div>
                                            <div className="job-ad-bottom">
                                                <span className="icon">推广</span>
                                                <span className="title">{job.agency}</span>
                                            </div>
                                        </div>
                                        : null
                                    }
                                </div>
                            </div>
                        ]
                        : [
                            util.minHeight(".8rem"),
                            <JobItem job={job} onClick={() => {sessionStorage.setItem("linkresearcher:configSize", `${this.state.from}`)}}/>
                        ]
                    })}

                    {this.state.jobs.length ? null : util.empty(
                        "更多职位正在努力上传中，请保持关注~",
                        { background: "white", marginTop: "1.25rem" },
                        "box-shadow"
                    )}

                    {this.state.jobs.length ? !this.state.searchEnd && (
                        <div style={{ padding: ".8rem 1rem" }}>
                            <button className="bg-color-primary"
                                style={{ width: "100%", height: "3rem", borderRadius: 0, fontSize: "1rem" }}
                                onClick={() => this.search(true)}>
                                查看更多
                            </button>
                        </div>
                    ) : null}
                    {this.state.jobs.length > 0 && this.state.searchEnd ? util.minHeight(".8rem") : null}
                    {this.state.jobs.length === 0 && this.state.searchStopped ? (
                        <div className="text-center">
                            <img src={util.imageURL("no-search-result.png")}
                                style={{ width: "40%", marginTop: "6rem" }} />
                            <div className="text-gray" style={{ lineHeight: 1.8, marginTop: "1.5em" }}>
                                更多职位正在努力上传中，
                                <div>
                                    请保持关注……
                                </div>
                            </div>
                        </div>
                    ) : null}

                    {this.state.tab ? (
                        <Item fit style={{ background: `rgba(0,0,0,0.6)` }}
                            onClick={() => this.setState({ tab: 0 })} />
                    ) : null}
                </Item>
            </div>
        )
    }
}
