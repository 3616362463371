import * as React from "react"
import * as mockxhr from "mockxhr"
import { Box, Item } from "react-polymer-layout"

import { actions, API } from "../../flux"
import { SearchIcon } from "../../components/FontawesomeIcon"
import * as util from "../../util"

interface Props {
}

interface State {
    show: boolean
    query: string
    buzzwords: any[]
    promotion: any
}

export default class SearchPopup extends React.Component<Props, State> {
    promotionIndex = 0

    constructor(props) {
        super(props)
        this.state = {
            show: false,
            query: "",
            buzzwords: [],
            promotion: {},
        }
    }

    componentDidMount() {
        mockxhr.POST(API.promotion.search("SEARCH_BUZZWORD", "_", 99, 0), {}, resp => {
            this.setState({ buzzwords: resp.hits })
        })
        mockxhr.POST(API.promotion.search("AD", "_", 99, 0), {}, resp => {
            resp.hits.forEach(item => {
                if (item.type1 === "MOBILE_SEARCH_BOX") this.setState({ promotion: item })
            })
        })
        //console.log(this.state.query, "query......")
    }

    show() {
        this.setState({ show: true })
    }

    handleSearch() {
        location.href = "/searchall?tab=most&query=" + encodeURIComponent(this.state.query)
    }

    render() {
        return (
            <div className="transition-500"
                style={{
                    position: "fixed",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    background: "#fff",
                    zIndex: this.state.show ? 100 : -10,
                    opacity: this.state.show ? 1 : 0,
                }}>
                <Box center style={{ padding: "12px 14px", borderBottom: "1px solid #e5e5e5" }}>
                    <Item flex relative>
                        <input value={this.state.query}
                            style={{
                                borderRadius: 999, background: "#f4f7f7", fontSize: 12, width: "100%",
                                height: 32, paddingLeft: 16, paddingRight: 36, color: "#333",
                            }}
                            onChange={e => this.setState({ query: e.target.value })}
                            onKeyDown={e => e.keyCode === 13 && this.handleSearch()} />

                        <SearchIcon 
                            iStyle={{ display: "inline-block", position: "absolute", right: 12, top: 7, fontSize: 16 }} 
                            color="#999"
                            onClick={() => this.handleSearch()}
                            />
                    </Item>
                    {util.minWidth(12)}
                    <div style={{ color: "#999", fontSize: 13 }}
                        onClick={() => this.setState({ show: false })}>
                        取消
                    </div>
                </Box>
                <div
                    style={{
                        padding: "30px 0 10px", margin: "0 15px", fontSize: 13, color: "#999",
                        borderBottom: "1px solid #e5e5e5",
                    }}>
                    热门搜索
                </div>
                <div style={{ fontSize: 13, lineHeight: 1, padding: "10px 0", color: "#0070ff" }}>
                    {this.state.buzzwords.map(x =>
                        <div key={x.id} style={{ padding: "10px 15px", display: "inline-block" }}
                            onClick={() => {
                                actions.clickPromotion(x.id, () => this.setState({ query: x.title }, () => this.handleSearch()))
                            }}>
                            {x.title}
                        </div>
                    )}
                </div>
                <div style={{ padding: "0 15px" }}>
                    <img src={this.state.promotion.image} style={{ width: "100%" }}
                        onClick={() =>
                            actions.clickPromotion(this.state.promotion.id, location.href = this.state.promotion.link)
                        } />
                </div>
            </div>
        )
    }
}
