import * as React from "react"
import { Box, Item } from "react-polymer-layout"

import * as util from "../util"

interface Props {
    innerWidth?: string
    title: string
    confirmText?: any
    cancelText?: any
    onConfirm?: any
    content: any
    scrollContent?: boolean
    disabled?: boolean
    style?: any
}

interface State {
    open: boolean
}

export default class Modal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            open: false
        }
    }

    show() {
        this.setState({ open: true })
    }

    hide() {
        this.setState({ open: false })
    }

    render() {
        let style = {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(0,0,0,0.45)",
            zIndex: this.state.open ? 10 : -1,
        }
        let modalStyle = {
            maxHeight: "85%",
            width: this.props.innerWidth || "85%",
            ...this.props.style
        }
        let twoButtons = !!(this.props.confirmText && this.props.cancelText)

        return (
            <Box center centerJustified style={style as any}
                className={this.state.open ? "fade-in-500" : "fade-out-500"}>

                <Box vertical style={modalStyle as any} className="panel">
                    <div className={`bg-color-primary text-center`}
                        style={{ fontSize: "1.1rem", lineHeight: "3rem", color: "white", fontWeight: 700 }}>
                        {this.props.title}
                    </div>

                    <Item relative
                        style={{
                            padding: "1.33rem 1.33rem 0",
                            overflow: this.props.scrollContent ? "auto" : undefined
                        }}>
                        {this.props.content}
                    </Item>

                    <Box center centerJustified flex="1 0" style={{ padding: "1.33rem", fontSize: "1rem", minHeight: "4.2rem" }}>
                        {this.props.confirmText ? (
                            <Item flex>
                                <button className="bg-color-primary" style={{ height: "2.5rem", width: "100%", fontSize: "1rem" }}
                                    disabled={this.props.disabled} onClick={() => {
                                        if (this.props.onConfirm) this.props.onConfirm()
                                        this.hide()
                                    }}>
                                    {this.props.confirmText === true ? "确定" : this.props.confirmText}
                                </button>
                            </Item>
                        ) : null}
                        {twoButtons ? util.minWidth("1.33rem") : null}
                        {this.props.cancelText ? (
                            <Item flex>
                                <button style={{ height: "2.5rem", width: "100%", fontSize: "1rem" }}
                                    onClick={() => this.hide()}>
                                    {this.props.cancelText === true ? "取消" : this.props.cancelText}
                                </button>
                            </Item>
                        ) : null}
                    </Box>
                </Box>
            </Box>
        )
    }
}
