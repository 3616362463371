import * as React from "react"
import * as mockxhr from "mockxhr"
import { Box, Item } from "react-polymer-layout"

import { actions } from "../../flux"

interface State {
    profile: any
    show: boolean
}

export default class LoginMenu extends React.Component<{}, State> {
    constructor(props) {
        super(props)
        this.state = {
            profile: null,
            show: false,
        }
    }

    componentDidMount() {
        actions.api.profile.get(resp => {
            this.setState({ profile: resp })
        })
    }

    menu(title, href, onClick?) {
        return (
            <div style={{ padding: "1rem 1rem 1rem 3rem", borderTop: "1px solid #71ccc5", fontSize: "1rem" }}
                onClick={onClick || (() => location.href = href)}>
                {title}
            </div>
        )
    }

    render() {
        return this.state.profile ?
            (
                <div>
                    <i className="fa fa-bars text-primary" style={{ fontSize: "1.1rem" }}
                        onClick={() => this.setState({ show: true })} />

                    <Box vertical className="bg-color-primary"
                        style={{
                            position: "fixed",
                            width: "12rem",
                            right: this.state.show ? 0 : "-12rem",
                            top: 0,
                            height: "100%",
                            zIndex: 1,
                            color: "white",
                        }}>
                        <Box center style={{ height: "4rem", fontSize: "1.1rem" }}>
                            <i className="fa fa-user" style={{ padding: "1rem" }} />
                            <Item flex>
                                <div style={{ width: "100%", overflow: "hidden" }}>
                                    {this.state.profile.nickname}
                                </div>
                            </Item>
                            <div style={{ padding: "1rem", fontSize: "1.6rem" }}
                                onClick={() => this.setState({ show: false })}>
                                ×
                            </div>
                        </Box>

                        {this.menu("消息", "/my/messages")}
                        {this.menu("我的简历", "/profile/resume")}
                        {this.menu("职位收藏", "/my/stars")}
                        {this.menu("投递箱", "/my/applications")}
                        {this.menu("我的论文", "/my/theses")}
                        {this.menu("设置", "/profile/account")}

                        <Item flex />

                        {this.menu("退出登录", "", () => {
                            mockxhr.DELETE("/login/session", {}, () => location.reload())
                        })}
                    </Box>
                </div>
            ) : (
                <a href="/login">
                    <i className="fa fa-user text-primary" style={{ fontSize: "1.1rem" }} />
                </a>
            )
    }
}
