import * as React from "react"
import { Box, Item } from "react-polymer-layout"

import * as util from "../util"

export default class Footer extends React.Component {
    render() {
        let main = (
            <Box>
                <Item>
                    {util.textGray("关于领研网", { fontSize: 14, fontWeight: 700 })}
                    <div style={{ fontSize: 12, color: "#666", lineHeight: 2, padding: "6px 0", whiteSpace: "nowrap" }}>
                        <Box>
                            <a className="text-gray" href="/about/us">关于我们</a>
                            {util.minWidth((innerWidth - 266) / 2)}
                            <a className="text-gray" href="/about/ad">广告投放</a>
                        </Box>
                        <Box>
                            <a className="text-gray" href="/about/contact">联系我们</a>
                            {util.minWidth((innerWidth - 266) / 2)}
                            <a className="text-gray" href="/about/thesis">上传论文</a>
                        </Box>
                        <Box>
                            <a className="text-gray" href="/about/recruit">发布招聘</a>
                            {util.minWidth((innerWidth - 266) / 2)}
                            <a className="text-gray" href="/about/help">帮助中心</a>
                        </Box>
                    </div>
                </Item>
                <Item flex />
                <div style={{ textAlign: "center", marginTop: 6 }}>
                    <div style={{ fontSize: 10, color: "#999" }}>
                        扫描二维码获取领研网最新资讯
                        {util.minHeight(10)}
                    </div>
                    <Box>
                        {util.imageContain(util.imageURL("qrcode-old.jpg"), 60)}
                        {util.minWidth(20)}
                        {util.imageContain(util.imageURL("qrcode-new.jpg"), 60)}
                    </Box>
                </div>
            </Box>
        )
        return (
            <div style={{ padding: 15, background: "#e5e5e5" }}>
                {main}
                <div style={{ fontSize: 10, color: "#666", borderTop: "1px solid #c0c0c0", paddingTop: 10, textAlign: "center" }}>
                    Powered by &copy; 2008-{new Date().getFullYear()}
                </div>
            </div>
        )
    }
}
